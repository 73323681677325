import React, { Fragment } from "react";

import Layout from "../components/layout";
import SignUpForm from "../components/Account/SignUp";

const SignUpPage = () => (
  <Fragment>
    <h1>Registreren voor een een DirkKater.nl account</h1>
    <SignUpForm />
  </Fragment>
);

export default ({ location }) => (
  <Layout location={location}>
    <SignUpPage />
  </Layout>
);
